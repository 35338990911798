<template>
  <img :src="img"
    :alt="alt"
    class="h-auto w-760 lg:block"
    :class="{'hidden':!show}"
  >
</template>
<script>
export default {
  name: "LandingImage",
  props: {
    img: String,
    alt: String,
    show: Boolean,
  }
}
</script>

