<template>
  <div :key="num" class="lg:px-20">
    <span class="text-lg font-normal leading-normal lg:w-1/2 lg:px-24 min-h-full"
      v-html="txt">
    </span>
  </div>
</template>
<script>
export default {
  name: "LandingSectionMultiContent",
  props: {
    num: String,
    txt: String,
  }
}
</script>

