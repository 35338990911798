<template>
  <t-card variant="landing" :class="{'min-h-long-paragraph': longParagraph}">
    <div class="flex flex-col w-full">
      <div class="text-center text-3xl lg:text-5xl font-helvNeu leading-normal text-redAccentLight mt-8 lg:mt-0 lg:mb-16 mx-auto">
        <slot name="title">
        </slot>
      </div>
      <div :class="{
        'lg:grid lg:grid-cols-2 flex flex-col my-6 gap-4 lg:gap-8': center,
        'flex flex-col lg:flex-row': !center,
      }">
        <template v-if="!center">
          <div class="lg:w-1/2 lg:px-24">
            <div class="flex flex-col mb-8 gap-1.5">
              <slot name="leftSide">
              </slot>
            </div>
          </div>
          <div class="text-lg font-normal leading-normal mb-8 lg:w-1/2 lg:px-24 min-h-full">
            <slot name="rightSide">
            </slot>
          </div>
        </template>
        <template v-else>
          <slot name="center">
          </slot>
        </template>
      </div>
    </div>
  </t-card>
</template>

<script>
export default {
  name: "LandingSelect",
  props: {
    longParagraph: Boolean,
    center: Boolean,
  }
}
</script>

